
import {
  Navigate,
  Route,
  Routes,
} from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import Transitions from '@Pb/Web/src/Components/Layout/Transitions';

import App from '../App';

const AppRouter = () => {
    const location = useLocation();
    
    return (
        <AnimatePresence mode="wait" >
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Transitions><App /></Transitions>} />
                <Route path='/home' element={<Transitions><App /></Transitions>} />
                <Route path='/*' element={<Navigate to='/' />} />
                <Route path='*/*' element={<Navigate to='/' />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AppRouter;