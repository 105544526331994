import styles from "./Components.module.scss";
import logo from '../Assets/Img/Campfire.gif';

type Props = {
    color?: "black" | "red", // white default
    size?: "medium" | "small" | "xsmall", // large default
    iconLeft?: boolean,
    iconRight?: boolean,
    iconOnly?: boolean,
}

const BurnLogo = (props: Props) => {
    const heading = "BURN'N'EARN";
    const subHeading = "POKERBOTS";

    const getStyles = () => {
        let logoStyles = '';

        if (!props.iconOnly) {
            logoStyles = `${styles.logo}`;
        } else {
            logoStyles = `${styles.sideIcon}`;
        }

        if (props.color === "black") {
            logoStyles += " text-secondary";
        } else if (props.color === "red") {
            logoStyles += " text-primary";
        } else {
            logoStyles += " text-white";
        }

        return logoStyles;
    }

    const getSubStyles = () => {
        let logoStyles = `h5 ${styles.subFont}`;

        if (props.size === "medium") {
            logoStyles += ` ${styles.medium}`;
        } else if (props.size === "small") {
            logoStyles += ` ${styles.small}`;
        }

        return logoStyles;
    }

    const getIconStyles = () => {
        let logoStyles = styles.logoIcon;

        if (props.size === "medium") {
            logoStyles += ` ${styles.medium}`;
        } else if (props.size === "small") {
            logoStyles += ` ${styles.small}`;
        } else if (props.size === "xsmall") {
            logoStyles += ` ${styles.xsmall}`;
        } else {
            logoStyles += ` ${styles.large}`;
        }

        return logoStyles;
    }

    const renderHeader = () => {
        if (props.size === "medium") {
            return (
                <h3>{heading}</h3>
            )
        } else if (props.size === "small") {
            return (
                <h5>{heading}</h5>
            )
        }

        return (
            <h2>{heading}</h2>
        )
    }

    const renderIcon = () => {
        return (
            <img 
                className={getIconStyles()} 
                src={logo} 
                alt="burning fire logo"
            />
        )
    }

    if (props.iconLeft) {
        return (
            <div className={`${getStyles()} ${styles.sideIcon}`}>
                {renderIcon()}
                {renderHeader()}
                <span className={getSubStyles()}>{subHeading}</span>
            </div>
        )
    }

    if (props.iconRight) {
        return (
            <div className={`${getStyles()} ${styles.sideIcon}`}>
                {renderHeader()}
                <span className={getSubStyles()}>{subHeading}</span>
                {renderIcon()}
            </div>
        )
    }

    if (props.iconOnly) {
        return (
            <div className={getStyles()}>
                {renderIcon()}
            </div>
        )
    }
    
    return (
        <div className={getStyles()}>
            {renderIcon()}
            {renderHeader()}
            <span className={getSubStyles()}>{subHeading}</span>
        </div>
    )
}

export default BurnLogo;
