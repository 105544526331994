import {
    Card,
    CardBody,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import { FaDiscord } from 'react-icons/fa';
import { SiTwitter } from 'react-icons/si';
import { IconContext } from 'react-icons';
import styles from "./Components.module.scss";

type Props = {
    isOpen: boolean,
    onClose: () => void,
}

const SocialModal = (props: Props) => {
    const onClickDiscord = () => window.open("https://discord.gg/BrfzdWZh")
                
    const onClickTwitter = () => window.open("https://twitter.com/BURNnEARNpoker")
                
    return (
        <Modal
            className={`modal-dark`}
            isOpen={props.isOpen}
            toggle={props.onClose}
            size={"lg"}
            centered
        >
            <ModalHeader toggle={props.onClose}>
                <h5 className="modal-title" id="modal-title-default">
                    Get in touch
                </h5>
            </ModalHeader>
            <ModalBody className={`pb-5`}>
                <Row>
                    <Col lg="6">
                        <div className={`d-flex justify-content-center`}>
                            <Card onClick={onClickDiscord} className={`${styles.pixButton} ${styles.counterCard} ${styles.pointerClick}`}>
                                <CardBody className="p-3">
                                    <Row>
                                        <Col lg="8">
                                            <div className="d-flex h-100 align-items-center">
                                                <p className="text-sm mb-0 text-uppercase font-weight-bold">
                                                    Discord
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg="4" className="text-end">
                                            <div className="icon icon-shape text-center">
                                                <IconContext.Provider value={{ className: styles.discord }}>
                                                    <FaDiscord />
                                                </IconContext.Provider>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <p className={`${styles.textStart} font-weight-bolder mt-3`}>
                                                <a href="https://discord.gg/BrfzdWZh">Join our Channel</a>
                                            </p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col lg="6" >
                        <div className={`d-flex justify-content-center`}>
                            <Card onClick={onClickTwitter} className={`${styles.pixButton} ${styles.counterCard} ${styles.pointerClick}`}>
                                <CardBody className="p-3">
                                    <Row>
                                        <Col lg="8">
                                            <div className="d-flex h-100 align-items-center">
                                                <p className="text-sm mb-0 text-uppercase font-weight-bold">
                                                    Twitter
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg="4" className="text-end">
                                            <div className="icon icon-shape text-center">
                                                <IconContext.Provider value={{ className: styles.twitter }}>
                                                    <SiTwitter />
                                                </IconContext.Provider>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <p className={`${styles.textStart} font-weight-bolder mt-3`}>
                                                <a href="https://metro.co.uk/wp-content/uploads/2018/06/sec_16605710.jpg?quality=90&strip=all">Tweet with us</a>
                                            </p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default SocialModal;