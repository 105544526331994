import b from "../../Assets/cards/back.svg"
import styles from "./Cards.module.scss";
import { CardProps } from "./CardsTypes";

const Card = <T, >(props: (CardProps<T> & { card: string; })) => {
    if (props.back) {
        return (
            <img
                src={b}
                className={`${styles.playingCards} ${props.className ? props.className : ""}`}
                alt="card-back" 
                style={props.styles ? { ...props.styles, height: props.height } : { height: props.height }}
            />
        )
    } else {
        return (
            <img
                src={props.card}
                className={`${styles.playingCards} ${props.className ? props.className : ""}`}
                alt={props.card} 
                style={props.styles ? { ...props.styles, height: props.height } : { height: props.height }}
            />
        )
    }
}

export default Card;