export enum ChipType {
    Green = "Green",
    Blue = "Blue",
    Red = "Red",
    Black = "Black",
    White = "White",
}

export enum CardType {
    '2c' = "2c",
    '3c' = '3c',
    '4c' = '4c',
    '5c' = '5c',
    '6c' = '6c',
    '7c' = '7c',
    '8c' = '8c',
    '9c' = '9c',
    'tc' = 'tc', '10c' = 'tc',
    'jc' = 'jc',
    'qc' = 'qc',
    'kc' = 'kc',
    'ac' = 'ac',

    '2d' = '2d',
    '3d' = '3d',
    '4d' = '4d',
    '5d' = '5d',
    '6d' = '6d',
    '7d' = '7d',
    '8d' = '8d',
    '9d' = '9d',
    'td' = 'td', '10d' = 'td',
    'jd' = 'jd',
    'qd' = 'qd',
    'kd' = 'kd',
    'ad' = 'ad',

    '2h' = '2h',
    '3h' = '3h',
    '4h' = '4h',
    '5h' = '5h',
    '6h' = '6h',
    '7h' = '7h',
    '8h' = '8h',
    '9h' = '9h',
    'th' = 'th', '10h' = 'th',
    'jh' = 'jh',
    'qh' = 'qh',
    'kh' = 'kh',
    'ah' = 'ah',

    '2s' = '2s',
    '3s' = '3s',
    '4s' = '4s',
    '5s' = '5s',
    '6s' = '6s',
    '7s' = '7s',
    '8s' = '8s',
    '9s' = '9s',
    'ts' = 'ts', '10s' = 'ts',
    'js' = 'js',
    'qs' = 'qs',
    'ks' = 'ks',
    'as' = 'as',
}

export enum SpecialCardType {
    'db' = "db",
}