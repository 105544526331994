import { motion } from "framer-motion";
import { ReactNode } from "react";

const animationConfiguration = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

type Props = { 
    children: ReactNode 
}

const Transitions = (props: Props) => {
    return (
        <motion.div
            variants={animationConfiguration}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ 
                duration: 1 
            }}
        >
            {props.children}
        </motion.div>
    );
};
export default Transitions;