import Card from "./CardBase"
import { CardProps } from "./CardsTypes"
import { CardType } from "@Pb/Shared/src/Types/Poker"

// two color normal face
import tc2c from "../../Assets/cards/2color/2C.svg"
import tc3c from "../../Assets/cards/2color/3C.svg"
import tc4c from "../../Assets/cards/2color/4C.svg"
import tc5c from "../../Assets/cards/2color/5C.svg"
import tc6c from "../../Assets/cards/2color/6C.svg"
import tc7c from "../../Assets/cards/2color/7C.svg"
import tc8c from "../../Assets/cards/2color/8C.svg"
import tc9c from "../../Assets/cards/2color/9C.svg"
import tcTc from "../../Assets/cards/2color/TC.svg"
import tcJc from "../../Assets/cards/2color/JC.svg"
import tcQc from "../../Assets/cards/2color/QC.svg"
import tcKc from "../../Assets/cards/2color/KC.svg"
import tcAc from "../../Assets/cards/2color/AC.svg"

import tc2d from "../../Assets/cards/2color/2D.svg"
import tc3d from "../../Assets/cards/2color/3D.svg"
import tc4d from "../../Assets/cards/2color/4D.svg"
import tc5d from "../../Assets/cards/2color/5D.svg"
import tc6d from "../../Assets/cards/2color/6D.svg"
import tc7d from "../../Assets/cards/2color/7D.svg"
import tc8d from "../../Assets/cards/2color/8D.svg"
import tc9d from "../../Assets/cards/2color/9D.svg"
import tcTd from "../../Assets/cards/2color/TD.svg"
import tcJd from "../../Assets/cards/2color/JD.svg"
import tcQd from "../../Assets/cards/2color/QD.svg"
import tcKd from "../../Assets/cards/2color/KD.svg"
import tcAd from "../../Assets/cards/2color/AD.svg"

import tc2h from "../../Assets/cards/2color/2H.svg"
import tc3h from "../../Assets/cards/2color/3H.svg"
import tc4h from "../../Assets/cards/2color/4H.svg"
import tc5h from "../../Assets/cards/2color/5H.svg"
import tc6h from "../../Assets/cards/2color/6H.svg"
import tc7h from "../../Assets/cards/2color/7H.svg"
import tc8h from "../../Assets/cards/2color/8H.svg"
import tc9h from "../../Assets/cards/2color/9H.svg"
import tcTh from "../../Assets/cards/2color/TH.svg"
import tcJh from "../../Assets/cards/2color/JH.svg"
import tcQh from "../../Assets/cards/2color/QH.svg"
import tcKh from "../../Assets/cards/2color/KH.svg"
import tcAh from "../../Assets/cards/2color/AH.svg"

import tc2s from "../../Assets/cards/2color/2S.svg"
import tc3s from "../../Assets/cards/2color/3S.svg"
import tc4s from "../../Assets/cards/2color/4S.svg"
import tc5s from "../../Assets/cards/2color/5S.svg"
import tc6s from "../../Assets/cards/2color/6S.svg"
import tc7s from "../../Assets/cards/2color/7S.svg"
import tc8s from "../../Assets/cards/2color/8S.svg"
import tc9s from "../../Assets/cards/2color/9S.svg"
import tcTs from "../../Assets/cards/2color/TS.svg"
import tcJs from "../../Assets/cards/2color/JS.svg"
import tcQs from "../../Assets/cards/2color/QS.svg"
import tcKs from "../../Assets/cards/2color/KS.svg"
import tcAs from "../../Assets/cards/2color/AS.svg"

export const standardCardDict: { [key in CardType]: string } = {
    [CardType["2c"]]: tc2c,
    [CardType["3c"]]: tc3c,
    [CardType["4c"]]: tc4c,
    [CardType["5c"]]: tc5c,
    [CardType["6c"]]: tc6c,
    [CardType["7c"]]: tc7c,
    [CardType["8c"]]: tc8c,
    [CardType["9c"]]: tc9c,
    [CardType.tc]: tcTc,
    [CardType.jc]: tcJc,
    [CardType.qc]: tcQc,
    [CardType.kc]: tcKc,
    [CardType.ac]: tcAc,

    [CardType["2d"]]: tc2d,
    [CardType["3d"]]: tc3d,
    [CardType["4d"]]: tc4d,
    [CardType["5d"]]: tc5d,
    [CardType["6d"]]: tc6d,
    [CardType["7d"]]: tc7d,
    [CardType["8d"]]: tc8d,
    [CardType["9d"]]: tc9d,
    [CardType.td]: tcTd,
    [CardType.jd]: tcJd,
    [CardType.qd]: tcQd,
    [CardType.kd]: tcKd,
    [CardType.ad]: tcAd,

    [CardType["2h"]]: tc2h,
    [CardType["3h"]]: tc3h,
    [CardType["4h"]]: tc4h,
    [CardType["5h"]]: tc5h,
    [CardType["6h"]]: tc6h,
    [CardType["7h"]]: tc7h,
    [CardType["8h"]]: tc8h,
    [CardType["9h"]]: tc9h,
    [CardType.th]: tcTh,
    [CardType.jh]: tcJh,
    [CardType.qh]: tcQh,
    [CardType.kh]: tcKh,
    [CardType.ah]: tcAh,

    [CardType["2s"]]: tc2s,
    [CardType["3s"]]: tc3s,
    [CardType["4s"]]: tc4s,
    [CardType["5s"]]: tc5s,
    [CardType["6s"]]: tc6s,
    [CardType["7s"]]: tc7s,
    [CardType["8s"]]: tc8s,
    [CardType["9s"]]: tc9s,
    [CardType.ts]: tcTs,
    [CardType.js]: tcJs,
    [CardType.qs]: tcQs,
    [CardType.ks]: tcKs,
    [CardType.as]: tcAs,
}

const CardsStandard = (props: CardProps) => {
    return (
        <Card {...props} card={standardCardDict[props.cardType]} />
    )
}

export default CardsStandard
