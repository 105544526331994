import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from 'reactstrap';
import { shuffle } from 'lodash';
import { motion, useAnimation, Variants } from 'framer-motion';

import { CardType, SpecialCardType } from '@Pb/Shared/src/Types/Poker';
import Separator from '@Pb/Web/src/Components/Layout/Separator';
import CardsStandard from '@Pb/Web/src/Components/Cards/Standard';
import CardsSpecial from '@Pb/Web/src/Components/Cards/Special';

import BurnFooter from './Components/Footer';
import BurnNav from './Components/Nav';
import BurnHeader from './Components/Header';
import BurnLogo from './Components/Logo';
import MoonMove from "./Components/Moon/MoonMove";

import RocketClubIcon from './Assets/Img/Creature.png';
import ProfessorSpadeIcon from './Assets/Img/professorspade.jpg';
import DiamondDuckIcon from './Assets/Img/DiamondDuck.png';
import HeartBeatsIcon from './Assets/Img/heartbeats.jpg';
import FireLogs from './Assets/Img/firelogs.gif';

import scales from "./Assets/Img/SupplyAndDemandP.png";
import styles from "./Components/Components.module.scss";
import FAQs from './Components/Faqs/FAQs';
import SocialModal from './Components/SocialModal';
import { useState } from 'react';

const App = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const onClickHeartTwitter = () => window.open("https://twitter.com/BnEHeartBeatz")
    const onClickDuckTwitter = () => window.open("https://twitter.com/BneDiamondDuck")
    const onClickSpadeTwitter = () => window.open("https://twitter.com/BneProfessorSpade")
    const onClickClubTwitter = () => window.open("https://twitter.com/BneRocketClub")
       
    const controls = useAnimation();

    const cardWallContainer: Variants = {
        stack: {
        },
        hand: {
            transition: {
                duration: 3,
            },
        },
    }

    const renderCardWall = () => {
        let cardWall: JSX.Element[] = [];
        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        const height = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
        const cardsHigh = height > 0 ? Math.ceil(height / 200) : 6;
        const cardsAcross = width > 0 ? Math.ceil(width / 143) : 15;
        const cardWallSize = Math.ceil(cardsAcross * cardsHigh);
        const cardTypes = Object.values(CardType);
        const cardTypesLength = cardTypes.length;
        let verticalRows = -1;
        let i = 0;
        for (i = 0; i < cardWallSize; i++) {
            if ((i % cardsAcross) === 0) {
                verticalRows++;
            }

            cardWall.push(<CardsStandard
                cardType={cardTypes[i % cardTypesLength]}
                height="200px"
                back={false}
                className={"animatedPlayingCards"}
                key={"card_" + i.toString()}
                styles={{ top: verticalRows * 200, left: (i % cardsAcross) * 143, }}
            />)
        }

        cardWall = shuffle(cardWall);

        cardWall.push(<CardsStandard
            cardType={CardType.ad}
            height="200px"
            back={false}
            className={"animatedPlayingCards"}
            key={"card_" + (1 + i).toString()}
            styles={{ top: (1 + verticalRows) * 200, left: ((i + 1) % cardsAcross) * 143, }}
        />);

        let j = 0;
        const cardHand: JSX.Element[] = [];
        for (j = 0; j < 4; j++) {
            cardHand.push(<motion.div
                className={styles.posAbs}
                variants={{
                    stack: {
                        x: "59.6vw", y: 96
                    },
                    hand: {
                        rotate: -20 + (12 * j),
                        x: `${59.6 + (0.4 * j)}vw`,
                        //x: "58vw", y: 96 
                    },
                } as Variants}
                key={"cardHand_" + j.toString()}
            >
                <CardsStandard
                    cardType={cardTypes[j % cardTypesLength]}
                    height="200px"
                    back={false}
                />
            </motion.div>)
        }

        cardHand.push(<motion.div
            className={styles.posAbs}
            variants={{
                stack: {
                    x: "59.6vw", y: 96
                },
                hand: {
                    rotate: -20 + (12 * j),
                    x: `${59.6 + (0.4 * j)}vw`,
                },
            } as Variants}
            key={"cardHand_" + (1 + j).toString()}
        >
            <CardsStandard
                cardType={CardType.ad}
                height="200px"
                back={false}
            />
        </motion.div>);

        return (
            <>
                <motion.div
                    className={styles.cardHand}
                    variants={cardWallContainer}
                    animate={controls}
                    initial="stack"
                >
                    {cardHand}
                </motion.div>
                <div
                    id="cardSheet"
                    className={styles.cardSheet}
                >
                    {cardWall}
                </div>
            </>
        );
    }

    const cardVariants: Variants = {
        offscreen: {
            y: 300,
            opacity: 0,
            rotate: -18,
        },
        onscreen: {
            opacity: 1,
            y: 0,
            rotate: -18,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    };

    return (
        <div>
            <BurnNav onClose={toggle} />
            <main className="profile-page">
                {/*<div className={styles.burn}>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                    <div className={styles.flame}></div>
                </div>*/}
                {/*renderCardWall()*/}
                <SocialModal isOpen={isOpen} onClose={toggle} />
                <section className="section-profile-cover section-lg section-shaped my-0">
                    <BurnHeader />
                    <Container className="py-lg-md d-flex">
                        <div className="col px-0">
                            <Row>
                                <Col lg="6">
                                    <div className={styles.logoHolder}>
                                        <BurnLogo />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className={`${styles.minH200} ${styles.counterCardContainer}`}>
                                        {/*<Card className={styles.counterCard}>
                                            <CardBody className="p-3">
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="numbers">
                                                            <p className="text-sm mb-0 text-uppercase font-weight-bold">
                                                                Burned
                                                            </p>
                                                            <h5 className="font-weight-bolder">
                                                                $53,000
                                                            </h5>
                                                            <p className="mb-0">
                                                                <span className="text-success text-sm font-weight-bolder">+55%</span>
                                                                since yesterday
                                                            </p>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="text-end">
                                                        <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>*/}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Separator />
                </section>
                <section className="section section-lg pt-lg-0 mt--100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row className="row-grid">
                                    <Col lg="4">
                                        <Card className={`card-lift--hover shadow border-0`}>
                                            <CardBody className="py-5">
                                                <h6 className={`${styles.pixFont} ${styles.campFire} text-uppercase`}>
                                                    LUNA CLASSIC
                                                </h6>
                                                <h5 className={`${styles.descCards} description my-3`}>
                                                    LUNC crashed and fell to Earth with too many coins in circulation. Join us to send LUNC back to the moon.
                                                </h5>
                                                <Button
                                                    className={`${styles.pixButton} ${styles.pixFont} ${styles.campFireBg} mt-4`}
                                                    color="primary"
                                                    href="#lunc"
                                                >
                                                    about LUNC
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className={`card-lift--hover shadow border-0`}>
                                            <CardBody className="py-5">
                                                <h6 className={`${styles.pixFont} ${styles.campOrange} text-uppercase`}>
                                                    The Burn
                                                </h6>
                                                <h5 className={`${styles.descCards} description my-3`}>
                                                    Burn your LUNC to aid the community and increase the scarcity of everyone's coins, pushing value up.
                                                </h5>
                                                <Button
                                                    className={`${styles.pixButton} ${styles.pixFont} ${styles.campOrangeBg} mt-4`}
                                                    color="success"
                                                    href="#burn"
                                                >
                                                    Burn with us
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className={`card-lift--hover shadow border-0`}>
                                            <CardBody className="py-5">
                                                <h6 className={`${styles.pixFont} ${styles.campYellow} text-uppercase`}>
                                                    Gameplay
                                                </h6>
                                                <h5 className={`${styles.descCards} description my-3`}>
                                                    Decrease the number of coins in circulation by automating your Texas hold'em games and earn while you sleep.
                                                </h5>
                                                <Button
                                                    className={`${styles.pixButton} ${styles.pixFont} ${styles.campYellowBg} mt-4`}
                                                    color="warning"
                                                    href="#play"
                                                >
                                                    Play Poker
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section pb-lg-0">
                    <Container>
                        <Row className={`${styles.minH500} align-content-center justify-content-center`}>
                            <Col className="order-lg-1 h-100" lg="7">
                                <div className={`${styles.section3TextContainer}`}>
                                    <div className={`${styles.section3Text} align-content-start justify-content-center`}>
                                        <h1 className={`${styles.pixFont} display-4 text-red`}>
                                            <span>Everyone sees what you appear to be,{" "}</span>
                                            few experience what you really are.
                                        </h1>
                                    </div>
                                    <div className={`mt-lg-5 ${styles.blockBgText}`}>
                                        Experience Poker in a new way and play in a fun, massively multiplayer world of trained poker bots.
                                        Compete with opponents across the globe for chips and prizes, climb the ranks to crown your bot the poker champion, and raise the stakes by putting your bot on the line in our hardcode mode.
                                    </div>
                                </div>
                            </Col>
                            <Col className="order-lg-2 mt-md-0 mt-6" lg="5">
                                <motion.div
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: false, amount: 0.8 }}
                                    className={`${styles.section3Image} align-items-center justify-content-center`}
                                >
                                    <motion.div variants={{
                                        ...cardVariants,
                                        onscreen: {
                                            ...cardVariants.onscreen,
                                            x: 110,
                                        },
                                    }}>
                                        <CardsSpecial
                                            cardType={SpecialCardType.db}
                                            height="200px"
                                            back={false}
                                        />
                                    </motion.div>
                                    <motion.div variants={{
                                        ...cardVariants,
                                        onscreen: {
                                            ...cardVariants.onscreen,
                                            x: 20,
                                        },
                                    }}>
                                        <CardsSpecial
                                            cardType={SpecialCardType.db}
                                            height="200px"
                                            back={false}
                                        />
                                    </motion.div>
                                    <motion.div variants={{
                                        ...cardVariants,
                                        onscreen: {
                                            ...cardVariants.onscreen,
                                            x: 71,
                                            y: -15,
                                            rotate: 14,
                                        },
                                    }}>
                                        <CardsSpecial
                                            cardType={SpecialCardType.db}
                                            height="200px"
                                            back={false}
                                        />
                                    </motion.div>
                                    <motion.div variants={{
                                        ...cardVariants,
                                        onscreen: {
                                            ...cardVariants.onscreen,
                                            x: -10,
                                            y: 4,
                                            rotate: 16,
                                        },
                                    }}>
                                        <CardsSpecial
                                            cardType={SpecialCardType.db}
                                            height="200px"
                                            back={false}
                                        />
                                    </motion.div>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section pt-0">
                    <Container>
                        <Row className={`${styles.section4} pt-lg justify-content-center`}>
                            <Col lg="12">
                                <h1 className={`display-3 text-center text-white pb-lg`}>
                                    Roadmap
                                </h1>
                                <Row className="row-grid">
                                    <Col lg="6" className={`${styles.disflex}`} >
                                        <Card className="w-100 shadow border-0">
                                            <CardHeader><h4>Q1 2023</h4></CardHeader>
                                            <CardBody className="py-5">
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap1" checked />
                                                    <h5>Design logo</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap1" checked />
                                                    <h5>Have discord set up</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap1" checked />
                                                    <h5>Landing page</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap1" />
                                                    <h5>Poker engine complete</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap1" />
                                                    <h5>First cut interface complete</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap1" />
                                                    <h5>Burn goal: 50,000</h5>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className={`${styles.disflex}`}>
                                        <Card className="w-100 shadow border-0">
                                            <CardHeader><h4>Q2 2023</h4></CardHeader>
                                            <CardBody className="py-5">
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Wallet integration</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Twitter Competition</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Bot training</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>1st gen graphics</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Source 100 alpha testers</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Alpha test poker site (without bots)</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Alpha test bots</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Launch alpha showcase videos published to YouTube</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap2" />
                                                    <h5>Burn goal: 200,000</h5>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="row-grid">
                                    <Col lg="6" className={`${styles.disflex}`}>
                                        <Card className="w-100 shadow border-0">
                                            <CardHeader><h4>Q3 2023</h4></CardHeader>
                                            <CardBody className="py-5">
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap3" />
                                                    <h5>Integrate alpha feedback</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap3" />
                                                    <h5>Natural language bot training</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap3" />
                                                    <h5>Public beta test launch</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap3" />
                                                    <h5>2nd gen graphics</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap3" />
                                                    <h5>Launch beta showcase videos published to YouTube</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap3" />
                                                    <h5>Public working poker site (without bots, but can play against house bots when not enough users)</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap3" />
                                                    <h5>Burn goal: 500,000</h5>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className={`${styles.disflex}`}>
                                        <Card className="w-100 shadow border-0">
                                            <CardHeader><h4>Q4 2023</h4></CardHeader>
                                            <CardBody className="py-5">
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap4" />
                                                    <h5>Bot training tutorial videos published to YouTube</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap4" />
                                                    <h5>Integrate beta feedback</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap4" />
                                                    <h5>Pot bots launch live!</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap4" />
                                                    <h5>Launch event</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap4" />
                                                    <h5>Goal: 5,000 people on discord</h5>
                                                </div>
                                                <div className={styles.checkboxes}>
                                                    <input readOnly type="checkbox" name="roadmap4" />
                                                    <h5>Burn goal: 10,000,000</h5>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="lunc" className={`section section-lg pb-0 ${styles.darkSectionBg2}`}>
                    <Container className="p-0">
                        <Row className={"align-content-center justify-content-between"}>
                            <Col lg="12">
                                <h1 className={`${styles.pixFont} display-4 text-center text-white pb-md`}>
                                    The fun way to burn
                                </h1>
                                <div className={`${styles.blockBgText}`}>
                                Play poker and have fun burning your LUNC. 
                                Train your poker bot to earn LUNC while you sleep! 
                                Your coins are transacted securely and anonymously through the wallet. 
                                Take Luna Classic back to the moon by playing poker with LUNC and watch the oversupply drop. Every transaction reduces the amount of LUNC in circulation, which will increase the value of your LUNC. Everybody wins!
                            </div>
                            </Col>
                        </Row>
                    </Container>
                    <MoonMove />
                </section>
                <section className={`section section-lg ${styles.darkSectionBg}`}>
                    <Container>
                        <Card className={`${styles.bgGradientFire} border-0`}>
                            <div className="p-5">
                                <Row className="align-items-center">
                                    <Col lg="8">
                                        <h3 className={`${styles.pixFont} text-white`}>
                                            Through flame and ash a new coin is born.
                                        </h3>
                                        <p className={`lead text-white mt-3`}>
                                            The road to recovery is through the ashes of a phoenix. Help us take flight and burn while you play.
                                        </p>
                                    </Col>
                                    <Col className="ml-lg-auto" lg="4">
                                        <Button
                                            block
                                            className={`${styles.pixButton} ${styles.campYellowBg} ${styles.pixFont} align-items-center`}
                                            color="default"
                                            onClick={toggle}
                                            size="lg"
                                        >
                                            Join the Burn
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Container>
                </section>
                <section id="burn" className="section pb-lg-0">
                    <Container>
                        <Row className={`${styles.minH500} align-content-center justify-content-center`}>
                            <Col className="order-lg-1" lg="5">
                                <img
                                    alt="..."
                                    className={`${styles.scales}`}
                                    src={scales}
                                />
                            </Col>
                            <Col className="order-lg-2" lg="7">
                                <div className={`${styles.section3TextContainer} align-content-start justify-content-center`}>
                                    <div className={`${styles.section3Text} mb-5 align-content-start justify-content-center`}>
                                        <h1 className={`${styles.pixFont} display-4 text-red`}>
                                            <span>Everyone benefits from a reduction in supply.</span>
                                        </h1>
                                    </div>
                                    <h1 className={`display-4`}>
                                        Play poker with LUNC and make it stronger!
                                    </h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className={`section section-lg`}>
                    <Container className="py-lg-md px-0 d-flex">
                        <Card className="shadow">
                            <Row className="p-lg-5">
                                <Col lg="8">
                                    <h1 className={`${styles.pixFont} display-3 text-white`}>
                                        Trillions of circulating coins
                                    </h1>
                                    <p className={`lead text-white`}>
                                        With an oversupply of LUNC and few places to use the coins, LUNC must reduce the supply. A burn is only possible when coins are being used so the demand must increase. With more places to spend the coin the supply will reduce from the burn and the value will increase. We are developing a new space for people to have fun using LUNC through poker. 
                                    </p>
                                    <p className={`lead text-white`}>  
                                        Burn'n'Earn Pokerbots - The fun way to burn!
                                    </p>
                                </Col>
                                <Col lg="4" className='align-content-center justify-content-center'>
                                    <div className={`${styles.logoHolder} h-100`}>
                                        <img
                                            alt="..."
                                            className={`${styles.fireLogs}`}
                                            src={FireLogs}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </section>
                <section id="play" className={`${styles.bgGradientFire} section`}>
                    <Container className="px-0">
                        <Row className="row-grid align-items-center">
                            <Col lg="12">
                                <div className="d-flex px-3">
                                    <div className="pl-4">
                                        <h4 className={`${styles.pixFont} display-4 text-white`}>
                                            We are a team of 4 lunatics
                                            <span className={`${styles.textFont}`}>And game developers from Australia</span>
                                        </h4>
                                        <p className="my-5 text-white">
                                            Our vision is to produce fun and moreish online entertainment that gets players excited by the possibilities.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col onClick={onClickDuckTwitter} className={`${styles.memberBlock} mb-5 mb-lg-0`} lg="3" md="6">
                                <div className={`${styles.teamPixButton} ${styles.teamImgHolder} shadow shadow-lg--hover`} >
                                    <img
                                        alt="..."
                                        className={`${styles.teamImg} img-center ${styles.imgVFluid}`}
                                        src={DiamondDuckIcon}
                                    />
                                </div>
                                <div className="pt-4 text-center">
                                    <h5 className="title">
                                        <span className={`${styles.pixFont} text-white d-block mb-1`}>Diamond Duck</span>
                                        <h6 className={`${styles.socialFont} h6 mb-1`}>@BneDiamondDuck</h6>
                                        <small className="h6">Security Specialist</small>
                                    </h5>
                                </div>
                            </Col>
                            <Col onClick={onClickHeartTwitter} className={`${styles.memberBlock} mb-5 mb-lg-0`} lg="3" md="6">
                                <div className={`${styles.teamPixButton} ${styles.teamImgHolder} shadow shadow-lg--hover`} >
                                    <img
                                        alt="..."
                                        className={`${styles.teamImg} img-center img-fluid`}
                                        src={HeartBeatsIcon}
                                    />
                                </div>
                                <div className="pt-4 text-center">
                                    <h5 className="title">
                                        <span className={`${styles.pixFont} text-white d-block mb-1`}>Heart Beats</span>
                                        <h6 className={`${styles.socialFont} h6 mb-1`}>@BnEHeartBeatz</h6>
                                        <small className="h6">Community Manager</small>
                                    </h5>
                                </div>
                            </Col>
                            <Col onClick={onClickSpadeTwitter} className={`${styles.memberBlock} mb-5 mb-lg-0`} lg="3" md="6">
                                <div className={`${styles.teamPixButton} ${styles.teamImgHolder} shadow shadow-lg--hover`} >
                                    <img
                                        alt="..."
                                        className={`${styles.teamImg} img-center img-fluid`}
                                        src={ProfessorSpadeIcon}
                                    />
                                </div>
                                <div className="pt-4 text-center">
                                    <h5 className="title">
                                        <span className={`${styles.pixFont} text-white d-block mb-1`}>Professor Spade</span>
                                        <h6 className={`${styles.socialFont} h6 mb-1`}>@BneProfessorSpade</h6>
                                        <small className="h6">Full Stack Developer</small>
                                    </h5>
                                </div>
                            </Col>
                            <Col onClick={onClickClubTwitter} className={`${styles.memberBlock} mb-5 mb-lg-0`} lg="3" md="6">
                                <div className={`${styles.teamPixButton} ${styles.teamImgHolder} shadow shadow-lg--hover`} >
                                    <img
                                        alt="..."
                                        className={`${styles.teamImg} img-center img-fluid`}
                                        src={RocketClubIcon}
                                    />
                                </div>
                                <div className="pt-4 text-center">
                                    <h5 className="title">
                                        <span className={`${styles.pixFont} text-white d-block mb-1`}>Rocket Club</span>
                                        <h6 className={`${styles.socialFont} h6 mb-1`}>@BneRocketClub</h6>
                                        <small className="h6">Full Stack Developer</small>
                                    </h5>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <FAQs />
            </main>
            <BurnFooter />
        </div>
    );
}

export default App;