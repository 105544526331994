import { useEffect, useState } from 'react';
import Headroom from 'headroom.js';
import { IconContext } from 'react-icons';
import { GiPokerHand } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Row,
    UncontrolledCollapse,
} from 'reactstrap';

import styles from './Components.module.scss';
import Logo from './Logo';

type Props = {
    onClose: () => void,
}

const PbNavbar = (props: Props) => {
    const [collapseClasses, setCollapseClasses] = useState<string>("");

    useEffect(() => {
        const headroom = new Headroom(document.getElementById("navbar-main")!);
        // initialise
        headroom.init();
    }, [])

    const onExiting = () => {
        setCollapseClasses("collapsing-out");
    };

    const onExited = () => {
        setCollapseClasses("");
    };

    return (
        <>
            <header className="header-global">
                <Navbar
                    className={"navbar-main navbar-transparent navbar-dark headroom"}
                    expand="lg"
                    id="navbar-main"
                >
                    <Container className={styles.navbarMain} >
                        <NavbarBrand className="mr-lg-3" to="/" tag={Link}>
                            <Logo size="xsmall" iconOnly />
                        </NavbarBrand>
                        <button className="navbar-toggler" id="navbar_global">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <UncontrolledCollapse
                            toggler="#navbar_global"
                            navbar
                            className={collapseClasses}
                            onExiting={onExiting}
                            onExited={onExited}
                        >
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/">
                                            <IconContext.Provider value={{ className: styles.logoIcon }}>
                                                <GiPokerHand />
                                            </IconContext.Provider>
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button className="navbar-toggler" id="navbar_global">
                                            <span />
                                            <span />
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                                <NavItem>
                                    <NavLink href="#luna">LUNC</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#burn">The Burn</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#play">Play to Ignite</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#community">Community</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav className="align-items-lg-center ml-lg-auto" navbar>
                                <NavItem className="d-none d-lg-block ml-lg-4">
                                    <Button
                                        className={`${styles.pixButton} ${styles.pixFont}`}
                                        onClick={props.onClose}
                                    >
                                        Join the Burn
                                    </Button>
                                </NavItem>
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
}

export default PbNavbar;
