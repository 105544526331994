import '@Pb/Web/src/Assets/vendor/nucleo/css/nucleo.css';
import './index.scss';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from './Routing/Routing';

ReactDOM.render(
    <BrowserRouter>
        <AppRouter />
    </BrowserRouter>, document.getElementById('root'));
