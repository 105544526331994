import styles from './Components.module.scss';

const BurnHeader = () => {
    return (
        <div className={`${styles.skyStyle} alpha-4`}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
        </div>
    )
}

export default BurnHeader;