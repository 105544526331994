import React from "react";
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";
import { SiTwitter } from 'react-icons/si';
import { FaDiscord } from 'react-icons/fa';
import { IconContext } from 'react-icons';

import styles from './Components.module.scss';

class Footer extends React.Component {
    render() {
        return (
            <>
                <footer className="footer footer-dark">
                    <Container>
                        <hr />
                        <Row className=" row-grid align-items-center mb-5">
                            <Col lg="8">
                                <h4 id="community" className=" mb-0 font-weight-light">
                                    Let's get in touch on any of these platforms.
                                </h4>
                            </Col>
                            <Col className="text-lg-center btn-wrapper" lg="4">
                                <Button
                                    className="btn-icon-only rounded-circle"
                                    color="twitter"
                                    href=""
                                    id="tooltip475038074"
                                    target="_blank"
                                >
                                    <span className="btn-inner--icon">
                                        <IconContext.Provider value={{ className: styles.fslg }}>
                                            <SiTwitter />
                                        </IconContext.Provider>
                                    </span>
                                </Button>
                                <UncontrolledTooltip delay={0} target="tooltip475038074">
                                    Follow us
                                </UncontrolledTooltip>
                                <Button
                                    className="btn-icon-only rounded-circle ml-1"
                                    color="discord"
                                    href="https://discord.gg/BrfzdWZh"
                                    id="tooltip837440414"
                                    target="_blank"
                                >
                                    <span className="btn-inner--icon">
                                        <IconContext.Provider value={{ className: styles.fslg }}>
                                            <FaDiscord />
                                        </IconContext.Provider>
                                    </span>
                                </Button>
                                <UncontrolledTooltip delay={0} target="tooltip837440414">
                                    Join us
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                        <hr />
                        <Row className=" align-items-center justify-content-md-between">
                            <Col md="12">
                                <Nav className="nav-footer copyright">
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            className="pl-0"
                                            target="_blank"
                                        >
                                            © {new Date().getFullYear()}{" "}
                                            Phoenix AI.
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </>
        );
    }
}

export default Footer;
