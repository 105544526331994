import { ReactNode, useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    Row,
} from 'reactstrap';
import styles from "./Help.module.scss";

type FaqItemProps = {
    title: string,
    description: string | ReactNode,
}

const FAQItem = (props: FaqItemProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Card className={`${styles.faqCardBg}`}>
            <CardHeader className={`${styles.faqCardHeaderBg}`}>
                <button 
                    className={`${styles.pixFont} btn btn-link btn-block text-left display-5`} 
                    type="button" 
                    onClick={toggle} 
                >
                    {props.title}
                </button>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody className="py-5" >
                    <div className={`${styles.textFont} description mt-3 text-white`}>
                        {props.description}
                    </div>
                </CardBody>
            </Collapse>
        </Card>
    );
}

const FAQs = () => {
    const questions: FaqItemProps[] = [
        {
            title: "How does it work?",
            description: "You create one or more bots that you then train to play poker like you. You can then have your bot verse other people's bots. You win, you earn LUNC!"
        },
        {
            title: "What is a pokerbot?",
            description: "A pokerbot is an AI bot that learns to play poker, and once trained, can play automatically without you having to attend. You can choose how often your bot plays, and can introduce stop losses to protect your stash. This enables you to earn while you sleep."
        },
        {
            title: "How do I train my bot?",
            description: <div>
                <p className={`description text-white`}>You train your bot by playing poker at a table against other bots. By playing each hand, and accepting or overriding the suggested action, the AI learns your style over time.</p>
                <p className={`description text-white`}>You can also explain to the bot in English why you chose to make a particular play, and it will remember that for future circumstances.</p>
                <p className={`description text-white`}>You can also replay past games to fine tune your bot's training so it continually improves its performance.</p>
            </div>
        },
        {
            title: "How am I competing against?",
            description: "You are competing against pokerbots trained by other members of the burn-n-earn community."
        },
        {
            title: "What type of poker would I be playing?",
            description: "We currently support Texas Hold 'em cash games and sit n' go tables."
        },
        {
            title: "When will my LUNC be burnt?",
            description: "When you join a table, and when you collect your winnings."
        },
        {
            title: "How much does it cost?",
            description: "Each table has a 5% rake, which is the commission fee taken by the burn-n-earn site for operating the game."
        },
        {
            title: "How long until my LUNC is worth millions?",
            description: "One day… maybe!  The more you burn, the more its worth. The power is in your hands, it is up to you."
        },
    ];
    
    return (
        <section className="section pt-lg-0">
            <Container className="py-lg-md d-flex">
                <div className="col px-0 text-center pt-100">
                    <h1 className={`${styles.pixFont} display-3 text-white`}>
                        Question Time
                    </h1>
                </div>
            </Container>
            <Container className="pt-lg">
                <Row className={"align-content-center justify-content-between"}>
                    <Col lg="12">
                        {questions.map((item, index) => <FAQItem 
                            key={`${item.title}_${index.toString()}`} 
                            title={item.title} 
                            description={item.description} 
                        />)}
                    </Col>
                </Row>                
            </Container>
        </section>
    );
}

export default FAQs;