import Card from "./CardBase"
import { CardProps } from "./CardsTypes"
import { SpecialCardType } from "@Pb/Shared/src/Types/Poker"

// two color normal face
import db from "../../Assets/cards/DiamondBot.svg"

export const specialCardDict: { [key in SpecialCardType]: string } = {
    [SpecialCardType.db]: db,
}

const CardsSpecial = (props: CardProps<SpecialCardType>) => {
    return (
        <Card {...props} card={specialCardDict[props.cardType]} />
    )
}

export default CardsSpecial
